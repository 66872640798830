import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { PureComponent, ReactElement } from "react";

import AspectRatioWrapper from "../components/aspectRatioWrapper";
import CategoryArticles from "../components/categoryArticles";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { getLanguageFromPath } from "../helpers/languageHelper";

interface GraphQLProps {
  data: {
    markdownRemark: {
      id: string;
      frontmatter: {
        category: string[];
        title: string;
        layout: string;
        thumbnail?: string;
      };
      html: string;
      excerpt: string;
      fileAbsolutePath: string;
    };
  };
  location: WindowLocation;
}

class PostTemplate extends PureComponent<GraphQLProps> {
  public render(): ReactElement {
    const { data, location } = this.props;
    const {
      id,
      excerpt,
      html,
      frontmatter: { title, category, thumbnail },
    } = data.markdownRemark;

    return (
      <Layout location={location}>
        <SEO title={title} description={excerpt} />
        <h1>{title}</h1>
        {thumbnail && (
          <AspectRatioWrapper ratio={4 / 3} mb={3}>
            <img src={thumbnail} style={{ objectFit: "cover" }} />
          </AspectRatioWrapper>
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
        <CategoryArticles
          category={category}
          language={getLanguageFromPath(location.pathname)}
          currentPostId={id}
        />
      </Layout>
    );
  }

  public static propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };
}

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        category
        title
        layout
        thumbnail
      }
      html
      excerpt
      fileAbsolutePath
    }
  }
`;

export default PostTemplate;
